@charset "utf-8";
@import 'variables';
@import 'mixin';
@import 'fontawesome';
@import 'animations';

@import '~bulma';
@import '~bulma-extensions/src/sass/index';
@import '~jquery-ui/themes/base/all.css';
@import '~bulma-helpers';
@import '~ios-overlay/style.css';
@import '~spin.js/spin.css';
@import '~anypicker/dist/anypicker-all.css';

.whs-brand {
  color: #444;
  text-transform: uppercase;

  span {
    color: $color_whs;
    margin-left: 5px; }

  img {
    margin-right: 5px; }

  .navbar-burger {
    z-index: 10;
    display: block;
    position: absolute;
    right: 10px;
    font-size: 24px;

    &.is-active {
      span {
        transform: none; } } }

  .navbar-item:hover,
  .navbar-burger:hover,
  .navbar-burger span {
    background: transparent!important; }

  .whs-brand-name {
    margin-top: -15px;
    color: #2b2b2b; }

  .whs-entity-name {
    position: absolute;
    font-size: 10px;
    bottom: 10px;
    color: #888;
    left: 45px; } }

.whs-navbar {
  .navbar-menu.is-active {
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    .navbar-end {
      margin-top: 50px; }

    .navbar-item {
      font-size: 20px;
      padding: 1rem 0.75rem; } } }

.whs-main-content {
  background: $color_white;
  min-height: 300px;

  .panel-heading {
    .delete {
      margin-top: 3px; } }

  .panel-block {
    background: #fff; }

  .tags.has-addons {
    .tag:first-child {
      min-width: 120px; } }

  .error-container {
    div {
      @extend .message;
      @extend .is-danger;

      &:not(:last-child) {
        margin-bottom: 0; }

      .error-field {
        @extend .message-body;
        border-color: $danger;
        border-radius: 0;
        padding: 1rem; } } }

  .is-scrollable {
    height: 450px;
    overflow-y: scroll; }

  .is-divider,
  .is-divider-vertical {
    margin: 1.5rem 0;
    border-top: .1rem solid $color_whs; }

  .tags:not(:last-child),
  .tags:last-child {
    margin-bottom: 0; }

  .tags:not(:first-child) {
    margin-top: 1rem; }

  .label {
    &.padded {
      padding: 0.4rem; } }

  .table {
    border: none;

    &.is-bordered {
      border: 1px solid #aaa; }

    &.active {
      border: 3px solid lightgreen; } }

  .is-ellipsis {
    width: 30vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

  .bg-progress {
    background: green;
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0; }

  .file.is-boxed {
    .file-name:not(:last-child) {
      border-radius: 0; } }

  .panel {
    border: 1px solid #ccc;
    overflow: hidden;

    &.is-success {
      border: 2px solid $green;

      .is-striped {
        tr:nth-child(even) {
          background: $green; } } }

    &.is-danger {
      border: 2px solid $red;

      .is-striped {
        tr:nth-child(even) {
          background: $red; } } } }

  .table {
    tbody {
      td, th {
        vertical-align: middle;

        &:last-child {
          border-right-width: 0; }

        &:first-child {
          border-left-width: 0; } }

      th {
        text-align: right; }

      tr:first-child {
        td, th {
          border-top-width: 0; } }

      tr:last-child {
        td, th {
          border-bottom-width: 0; } } } } }


.whs-scanner-summary {
  .card-summary {
    margin-bottom: 25px;
    cursor: pointer;

    .card-header-icon {
      color: $color_black; }

    .card-header-title a {
      color: $color_darkblue; } } }

.whs-footer {
  padding: 1rem; }


.whs-scanner-home {
  .tile {
    font-weight: bold;
    text-align: center;
    color: $black;

    span {
      line-height: 18px;
      margin-top: 10px;
      display: block; } } }

.whs-scanner-login {
  padding-top: 5rem;

  .avatar {
    margin-top: -90px;
    padding-bottom: 20px;

    img {
      box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
      background: #fff;
      height: 128px;
      padding: 8px; } } }

.whs-form-inward {
  .select {
    background: linear-gradient(#d6f9ff, #83cff3);
    font-weight: bold;

    select {
      background: linear-gradient(#d6f9ff, #83cff3);
      font-weight: bold; } }

  .input {
    @include input_generic_css;
    font-weight: bold; }

  tr {
    &.has-error {
      strong {
        color: #c00; } } } }

.whs-form-questions {
  .step-content {
    display: block!important; }

  .form-questions {
    .title {
      margin-top: 30px;

      &:first-child {
        margin-top: 0; } } }

  .field {
    .is-checkradio {
      &[type=radio],
      &[type=checkbox] {
        display: block!important;
        visibility: hidden;

        + label {
          color: $color_navy;
          font-weight: bold;
          padding-left: 3rem;
          border: 1px solid #94B5C4;
          background: linear-gradient(#d6f9ff, #bbe1ff);

          &:hover {
            background: linear-gradient(#bbe1ff, #d6f9ff); }

          &:before,
          &:after {
            top: 50%;
            left: 0.5rem;
            margin-top: -0.75rem;
            border-color: steelblue; } }

        &:checked {
          + label {
            border-color: #fff;
            background: #0f81cc;
            color: #fff;

            &:before,
            &:after {
              border-color: #fff!important; } } }

        &.option-1:checked {
          + label {
            background: green; } }

        &.option-2:checked {
          + label {
            background: darkred; } }

        &.option-3:checked,
        &.option-16:checked {
          + label {
            background: purple;

            &:hover {
              background: purple; } } } }

      &[type=radio] {
        &:checked {
          + label {
            &:after {
              background: #fff!important; } } }

        display: block!important; }

      &[type=checkbox] {
        + label {
          &:after {
            left: 1rem;
            top: 26px; } } } }

    .input {
      @include input_generic_css; } }

  .form-question {
    margin: 3px 1px 1px;

    &.has-error {
      .title {
        color: #c00; } }

    p {
      line-height: 30px; }

    &.is-danger {
      background: rgba(255, 0, 0, 0.2);
      border-bottom: 1px solid rgba(255, 0, 0, 0.5); }

    &:last-child {
      &.is-danger {
        border-bottom: 0; } } } }

.modal-error {
  .modal-card-foot {
    button {
      white-space: initial;
      height: auto; } } }

.pageloader {
  background: #fff;
  text-align: center;

  &::after {
    display: none!important; }

  img {
    animation: pulsate linear 0.5s infinite;
    display: block;
    margin: 0 auto 10px;
    width: 40px; }

  .title {
    color: $black;
    font-size: 12px;
    text-transform: uppercase;
    white-space: normal;
    margin-top: -25%; } }

// region utilities
.no-scroll {
  overflow: hidden; }

.is-small {
  font-size: 12px; }
// endregion

// region jquery ui
.ui-dialog .ui-dialog-titlebar {
  padding: 0; }

.ui-widget-header {
  background: transparent;
  border: 0; }

.disable-events-inactive {
  pointer-events: none;
  opacity: 0.5; }

.answer-flag {
  width: 0;
  height: 0; }
// endregion

// region ios overlay
$overlay_width: 280px;

.ios-overlay {
  width: $overlay_width;
  height: $overlay_width;
  margin-top: -($overlay_width / 2);
  margin-left: -($overlay_width / 2);

  img {
    width: 50%;
    margin-top: 10%; }

  .title {
    width: auto;
    left: 10px;
    right: 10px;
    bottom: 0;
    font-size: 22px;
    font-weight: bold; } }
// endregion
