@config '../../../tailwind.legacy.config.js';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .paperwork-dialog {
        .btn {
            @apply bg-blue-500 text-white font-bold py-2 px-4 rounded;
            @apply cursor-pointer;
        }

        input[type=file] {
            @apply block text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 p-1.5;
            @apply !w-full;
        }

        select {
            @apply w-full border border-gray-300 rounded p-1.5 mt-1;
            @apply !text-sm !ps-1.5 !pe-1.5;
        }

        input[type=submit] {
            @apply !ml-0 !mt-2 !text-sm;
        }

        p {
            &:first-child {

            }

            &:last-child {

            }

            @apply text-sm;
        }

        h4 {
            @apply !mb-2 !mt-2 font-bold;
        }
    }
}
