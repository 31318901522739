@import '~animate.css';

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateY(-15px); }

  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes pulsate {
  1% {
    transform: scale(1); }
  50% {
    transform: scale(0.8); }
  100% {
    transform: scale(1); } }

.fade {
  opacity: 0; }

.fade-in {
  animation: fade-in-right ease 0.75s forwards; }
