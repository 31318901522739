$color_whs: #53B7D3;
$color_black: #000;
$color_darkblue: #0f81cc;
$color_lightblue: #ADD8E6;
$color_navy: #11116c;
$color_white: #fff;

$text: $color_black;

$primary: $color_whs;
$navbar-item-hover-color: $color_black!important;
$navbar-item-color: $color_black!important;

$border: #aaa;
$input-focus-border-color: $color_whs;
$input-shadow: none;

$link-focus-border: $color_whs;
$link-hover-border: $color_whs;

$table-striped-row-even-background-color: #ccc;
$table-cell-padding: 0.5em;
$table-row-hover-background-color: #ddd;
